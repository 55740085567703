import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Layout from "../layout/layout";
import Error from "../components/Error/404";
import config from "../../data/SiteConfig";

class ErrorPage extends Component {
  render() {
    return (
        <Layout location={this.props.location} title="Error-layout">
            <div className="error-container">
            <Helmet>
                <title>{`404 | ${config.siteTitle}`}</title>
                <link rel="canonical" href={`${config.siteUrl}/404/`} />
            </Helmet>
            <Error />
            </div>
        </Layout>
    );
  }
}

export default ErrorPage;

