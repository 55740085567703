import React, { Component } from "react";
import Card from "react-md/lib/Cards/Card";
import "./Error.scss";

class Error extends Component {
  render() {
    return (
      <div className="md-grid mobile-fix">
        <Card className="md-grid md-cell--4" >
          <div className="error-wrapper">
            <div className="material-icons">error</div>
            <div className="error-code"><b>404- Page not found</b></div>
            <div className="error-text">
              <p>The Page you are looking for cannot be found. Please ensure that you have entered the address correctly. If the address is correct, the page may be temporarily unavailable or may have been removed.</p>
            </div>

            <a className="btn btn-go-back" href="\?TradeID={tradeId}" > <span className="btn-go-back-txt">Go Back to Home</span> <i className="right-arrow right"></i></a>
          </div>
        </Card>
      </div>
    );
  }
}
export default Error;
